@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  /* background: whitesmoke; */
  background: #f3f3f3;
}

.export-button {
  font-size: 8px;
}

/* No seu arquivo de estilos CSS ou no arquivo .css do seu componente */
.custom-modal-header {
  background-color: #3498db; /* Cor de fundo azul claro */
  color: #fff; /* Cor do texto branco */
  border-bottom: none; /* Remover a borda inferior */
  border-radius: 0.25rem 0.25rem 0 0; /* Adicionar borda arredondada apenas no canto superior */
  padding: 1rem; /* Espaçamento interno */
}

.custom-modal-header h5 {
  margin: 0; /* Remover margem padrão do título h5 */
}

.mt-page {
  margin-top: 90px; /* Adicionar margem ao topo */
  padding: 10px; /* Adicionar padding de 10px da esquerda para a direita */
  margin-left: 0 !important; /* Remover qualquer margem à esquerda com !important */
  /* Outros estilos podem ser mantidos ou adicionados conforme necessário */
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.titulo {
  margin-top: 80px;
}

.main-content {
  margin-left: 4.5rem; /* Ajuste conforme a largura da barra lateral */
  margin-top: -3rem; /* Add margin to the top */
  padding: 20px;
  /* transform: scale(0.9); Reduzir o conteúdo para 80% do tamanho original */
  transform-origin: top left; /* Definir o ponto de origem da transformação para o canto superior esquerdo */
}

.my-image {
  width: 20px;
  height: 20px;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.5;
}

p {
  color: #8b8b8b;
}

#root {
  height: 90vh;
}

/* NAVBAR */

.navbar {
  background-color: #0d6efd;
}

/* BANNER */

#banner {
  background-color: #0d6efd;
  color: #ffffff;
  padding: 100px 0 70px 0;
}

#banner img {
  width: 100%;
}

.btn-banner {
  margin: 8% 3% 5% 0;
}

/* FEATURES */

#features {
  padding: 7% 5%;
}

.feature-box {
  text-align: center;
  padding: 5%;
}

.icon {
  color: #0d6efd;
  margin-bottom: 15px;
}

/* TESTEMUNHOS */

#testemunho {
  background-color: #f3f3f3;
  color: #323338;
  text-align: center;
}

#testemunho img {
  border-radius: 50%;
  width: 10%;
  margin: 20px;
}

/* PRECOS */

#preco .titulo {
  margin-top: 5%;
}

#preco {
  color: #323338;
}

/* FOOTER */

#footer {
  padding: 3% 15%;
  text-align: center;
  background-color: #f3f3f3;
}

.th {
  color: #000; /* Define a cor do texto como preto (#000) */
}
