.modal-historico-saldo-dialog {
  min-width: 100%;
  min-height: 100vh;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.operation-type {
  display: flex;
  align-items: flex-end;
  gap: 15px;
}

.operation-type div {
  width: 300px;
}
