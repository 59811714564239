.card-pedido {
    border: none;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.pedido-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
}

.table {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
    vertical-align: middle;
}


.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 123, 255, 0.1);
}
