.autocomplete {
  width: 100%;
  position: relative;
  /* display: inline-block; */
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;

  border-top: none;
  z-index: 99;

  top: 100%;
  left: 0;
  right: 0;

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 250px;
}

.autocomplete-items > .autocomplete-item {
  padding: 10px;
  cursor: pointer;
  background-color: white;
  border-bottom: 1px solid grey;
}

.autocomplete-items div:hover {
  background-color: grey;
}

.autocomplete-clear {
  position: absolute;
  top: 1px;
  border-radius: 3px;
  right: 50px;
  z-index: 2;
  border: none;
  height: 94%;
  cursor: pointer;
  background-color: #ffffff;
}

.autocomplete-search {
  position: absolute;
  top: 1px;
  border-radius: 3px;
  right: 10px;
  z-index: 2;
  border: none;
  height: 94%;
  cursor: pointer;
  background-color: grey;
}

.autocomplete-img {
  height: 28px;
}
