/* Adicione estilos personalizados para o modal */
.custom-modal {
  max-width: 90%;
  margin: 0 auto;
  align-items: center;
}

/* Em seu arquivo CSS ou SCSS */
.customer-details {
  background-color: #f0f0f0; /* Cor de fundo de destaque */
  padding: 10px; /* Espaçamento interno para destacar o conteúdo */
  border: 1px solid #ccc; /* Borda para enfatizar a área */
  border-radius: 5px; /* Bordas arredondadas */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve para elevação */
}

/* styles.css */

/* Classe para estilização personalizada do modal */
.custom-modalzpl {
  max-width: 90%; /* Define a largura máxima do modal */
  min-width: 90%;
  width: auto; /* Permite que o modal tenha uma largura automática */
}

/* Estilo para o conteúdo dentro do modal */
.custom-modalzpl .modal-body {
  padding: 20px; /* Espaçamento interno para o conteúdo do modal */
  overflow: auto; /* Ativa a rolagem horizontal se necessário */
}

.item-row {
  background-color: #e9ecef; /* Cor de fundo cinza claro para itens normais */
}

.kit-item-row {
  background-color: #cfe2ff; /* Cor de fundo azul claro para itens do kit */
  color: #0056b3; /* Cor do texto para itens do kit */
}

.kit-item-row:hover {
  background-color: #b6d4fe; /* Cor ao passar o mouse sobre itens do kit */
}
