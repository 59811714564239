/* LockScreen.css */
.lock-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Alto valor para sobrepor outros elementos */
  }
  
  .lock-screen-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .user-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .password-input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .unlock-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .unlock-button:hover {
    background-color: #0056b3;
  }
  