.icone-acao {
  margin-left: 7px;
  margin-right: 7px;
}

.icon-blue {
  color: lightseagreen;
}

.col-est-matriz {
  text-align: center; /* Alinhamento no centro */
  width: 150px; /* Ajuste a largura conforme necessário */
}



.red {
  color: rgb(194, 74, 74);
}

.col-acao {
  width: 80px;
}

div.label-kit {
  background: #d35400;
  border-radius: 10px;
  width: 40px;
  padding: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
}

div.label-kit p {
  all: unset;
  color: white;
}
