.modal-expleroy-dialog {
  max-width: 100%;
  padding: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-expleroy-content {
  max-width: 100%;
}

.show-product {
  padding: 5px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  text-align: center;
}
