.container-wrapper {
  display: flex;
  justify-content: flex-end;
}

.export-search-container {
  display: flex;
  align-items: center;
}

.export-search-container .form-control {
  margin-left: 10px; /* Adiciona espaço entre os componentes */
}


.btn-smaller {
  font-size: 10px;
  padding: 5px 10px;
}

.resumo-item {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
}

.container h3 {
  margin-top: 0;
}

.summary p {
  margin: 5px 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.even {
  background-color: #f2f2f2;
}

.odd {
  background-color: #ffffff;
}

.checkbox-cell {
  width: 1px;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  margin-right: 5px;
}

.pagination li a {
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  color: #333;
}

.pagination li.active a {
  background-color: #333;
  color: #fff;
}

/* CSS for button alignment and spacing */
.input-group-append {
  display: flex;
  align-items: center;
}

.input-group-append label {
  margin-bottom: 0;
  margin-top: 0;
}

/* Add more specific styles as needed */
