@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.group {
  display: flex;
  justify-content: center;
  flex-direction: row;

  width: 680px;
  min-width: 680px;
}

.order-info {
  background: #d6dbdf;

  min-width: 500px;
  width: 500px;
  padding: 20px;
  border-radius: 25px;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
}

.order-info.enter {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: slide-top 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-info p {
  all: unset;
}

.conference {
  width: 100%;
  margin-top: 30px;
  padding: 20px;
}

.conference section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.conference main h1 {
  text-align: center;
  margin: 0;
}

.conference main .itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

div#modal-password {
  flex: 1;
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div#modal-password h1 {
  font-size: 18px;
}

div#modal-password button {
  margin-top: 15px;
}

article.order-item {
  background: #d6dbdf;

  min-width: 300px;
  width: 300px;

  height: 150px;
  min-height: 150px;

  border-radius: 15px;
  margin-top: 20px;
  margin-right: 10px;

  display: flex;
  flex-direction: column;
}

article.order-item p {
  all: unset;
  font-size: 12px;
}

article main {
  padding: 5px;
  flex: 1;

  display: flex;
  flex-direction: column;
}

article footer {
  width: 100%;
  height: 40px;
  padding: 5px;

  display: flex;
  align-items: center;
}

article footer div {
  width: 20px;
  height: 20px;

  border-radius: 15px;
  margin-bottom: 10px;
  margin-right: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}
