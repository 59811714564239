.modal-modificar-saldo-dialog {
  min-width: 100%;
  min-height: 100vh;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.operation-type {
  height: 100px;
  min-height: 100%;
}

.operation-type div {
  min-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
