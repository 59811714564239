body {
  background-color: white;
}

.card-body {
  padding-top: 20px;
}

.card {
  margin-bottom: 1rem;
}

#tr-table tbody tr td {
  font-size: 12px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

/* Estilo moderno para a tabela */
.modern-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden; 

}

/* Cabeçalho estilizado  Cinza*/
.modern-table thead th {
  background-color: #bac6d3; /* Azul moderno */
  color: #fff; /* Texto branco */
  font-weight: 600;
  padding: 14px 12px;
  text-align: left;
  font-size: 0.95rem;
}

/* Células da tabela */
.modern-table tbody td {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
  color: #333;
  font-size: 0.9rem;
}

/* Estilo de hover para linhas */
.modern-table tbody tr:hover {
  background-color: #f0f8ff; /* Azul claro suave */
  transition: background-color 0.3s ease;
}

/* Remover a borda inferior da última linha */
.modern-table tbody tr:last-child td {
  border-bottom: none;
}
