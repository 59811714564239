/* Estilo geral */
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

/* Estilo dos "cards" (antiga tabela) */
.boleto-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.boleto-card:hover {
  transform: scale(1.02);
}

.boleto-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.boleto-card .header h5 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.boleto-card .header .actions {
  display: flex;
  gap: 10px;
}

.boleto-card .details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.boleto-card .details div {
  flex-basis: 48%;
  margin-bottom: 10px;
  font-size: 14px;
}

.boleto-card .details div span {
  font-weight: bold;
}

.boleto-card .details div.value {
  color: #28a745;
  font-size: 16px;
  font-weight: bold;
}

.pagination__link--active {
  background-color: #007bff; /* Cor de fundo azul (ou qualquer cor que preferir) */
  color: white; /* Cor do texto branco */
  /* border-radius: 5px;
  padding: 5px 10px; */
}


/* Estilo para os botões */
.actions button {
 
  border: none;
  padding: 8px;
  color: #fff;
}

.actions button.download {
  background-color: #007bff;
}

.actions button.cancel {
  background-color: #dc3545;
}

.actions button.extend {
  background-color: #ffc107;
}

.boleto-card {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.boleto-card.vencido {
  background-color: #f8d7da; /* Vermelho claro */
  border-color: #f5c6cb;
}

.boleto-card.pago {
  background-color: #d4edda; /* Verde claro */
  border-color: #c3e6cb;
}

.boleto-card .details {
  margin-top: 10px;
}

.boleto-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boleto-card .actions button {
  margin-left: 5px;
}


/* Responsividade */
@media (max-width: 768px) {
  .boleto-card .details div {
    flex-basis: 100%;
  }

  .boleto-card .header {
    flex-direction: column;
    align-items: flex-start;
  }
}
