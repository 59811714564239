/* TrackModal.css */
.timeline {
  list-style-type: none;
  padding: 0;
}

.timeline-item {
  padding: 10px; /* Reduzindo o espaçamento interno */
  position: relative;
  background-color: #f8f9fa; /* Cor de fundo do item */
  margin-bottom: 10px; /* Reduzindo a margem inferior */
}

.timeline-item:last-child {
  margin-bottom: 0; /* Remove a margem do último item */
}

.timeline-content {
  margin-left: 20px; /* Margem para afastar o conteúdo do lado esquerdo */
}

.timeline-content h3 {
  margin-top: 0;
  margin-bottom: 5px; /* Reduzindo a margem inferior */
}

.timeline-content p {
  margin: 0;
  font-size: 14px; /* Reduzindo o tamanho da fonte */
}
