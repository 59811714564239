/* Adicione estilos globais para o body e fontes */
body {
  font-family: Arial, sans-serif;
  background-color: white;
  padding-top: 40px;
}
.custom-modalzpl {
  max-width: 90%; /* Define a largura máxima do modal */
  min-width: 90%;
  width: auto; /* Permite que o modal tenha uma largura automática */
}

.form-pedido select {
  border: 0px;
  background-color: #fff;
  height: 35px;
  cursor: pointer;
}

/* Estilo para o cabeçalho */
h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Estilo para o botão Editar */
.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Estilo para o botão Fechar no modal */
.close {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  opacity: 0.7;
}

.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 1;
}

/* Estilize o contêiner principal */
.contagem-frame-container {
  text-align: center; /* Centralize o conteúdo horizontalmente */
  padding: 20px; /* Adicione algum espaço ao redor do conteúdo */
  border: 1px solid #ccc; /* Adicione uma borda para destacar o componente */
  border-radius: 5px; /* Arredonde as bordas */
  background-color: #f5f5f5; /* Cor de fundo */
}

/* Estilize o cabeçalho do componente */
.contagem-frame-header {
  margin-bottom: 20px; /* Adicione algum espaço entre o cabeçalho e o conteúdo */
}

/* Estilize a tabela */
/* .contagem-frame-content table {
  width: 100%;


} */

/* Estilize as células da tabela conforme necessário */
.contagem-frame-content table th,
.contagem-frame-content table td {
  text-align: center; /* Centralize o conteúdo nas células */
  vertical-align: middle; /* Centralize verticalmente o conteúdo nas células */
  padding: 10px; /* Adicione algum espaço interno nas células */
}

/* Estilize a paginação conforme necessário */
.contagem-frame-content .pagination {
  justify-content: center; /* Centralize a paginação horizontalmente */
  margin-top: 20px; /* Adicione algum espaço acima da paginação */
}

/* styles.css */

/* Classe para reduzir o tamanho da fonte da tabela */
.custom-table {
  font-size: 12px; /* Ajuste o tamanho da fonte conforme necessário */
}

/* Estilos para a paginação */
.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Espaçamento superior para separar da tabela */
}

.Pagination.Item {
  margin: 0 5px;
}

/* Seu arquivo de estilos.css ou estilos.scss */

/* Classe para diminuir o tamanho da fonte */
.small-font {
  font-size: 10px; /* Ajuste o tamanho da fonte conforme necessário */
}

/* Se você quiser alterar o tamanho da fonte apenas nas células da tabela, você pode fazer isso */
.custom-table td.small-font {
  font-size: 10px; /* Tamanho da fonte para as células da tabela */
}

.th {
  color: #000; /* Define a cor do texto como preto (#000) */
}
