/* Isolando o CSS para o ResumoPedidosModal */
.resumo-pedidos-modal {
  position: relative;
}

/* Animação de oscilação */
@keyframes oscilar {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Isolando o CSS para o ResumoPedidosModal */
.resumo-pedidos-modal {
  position: relative;
}

/* Estilo para o botão flutuante moderno dentro do modal */
.resumo-pedidos-modal .btn-animado {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombras mais suaves */
  z-index: 9999; /* Garante que o botão fique acima de outros elementos */
  transition: all 0.3s ease-in-out;
  background-color: #ff9800; /* Cor de fundo laranja mais suave */
  border: none; /* Remove bordas */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateZ(0); /* Melhor performance no navegador */
  outline: none; /* Remove contorno de foco */
}

/* Efeito de hover - Aumento do botão */
.resumo-pedidos-modal .btn-animado:hover {
  background-color: #e68900; /* Cor de fundo mais escura no hover */
  transform: scale(1.1); /* Aumenta o botão quando passar o mouse */
}

/* Efeito de clique - leve redução */
.resumo-pedidos-modal .btn-animado:active {
  transform: scale(0.95); /* Leve redução no clique */
}


/* Estilos para o Modal com Layout de Dashboard */
.resumo-pedidos-modal .modal-content {
  border-radius: 15px;
  background-color: #f7f9fc;
}

.resumo-pedidos-modal .modal-header {
  border-bottom: 2px solid #ddd;
  background-color: #f0f4f8;
}

.resumo-pedidos-modal .modal-title {
  font-weight: 600;
  color: #333;
}

.resumo-pedidos-modal .card-dashboard {
  margin: 10px 0;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.resumo-pedidos-modal .card-dashboard:hover {
  transform: translateY(-5px);
}

.resumo-pedidos-modal .card-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.resumo-pedidos-modal .card-text {
  font-size: 24px;
  font-weight: bold;
  color: #ff5733;
}

/* Ícones dentro dos cards */
.resumo-pedidos-modal .card-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.resumo-pedidos-modal .card-icon .spin {
  animation: spin 2s linear infinite;
}

.resumo-pedidos-modal .card-icon .cancel {
  color: #dc3545; /* Vermelho para cancelados */
}

.resumo-pedidos-modal .card-icon .success {
  color: #28a745; /* Verde para sucesso */
}

.resumo-pedidos-modal .card-icon .pago {
  color: #007bff; /* Azul para pago */
}

.resumo-pedidos-modal .card-icon .aberto {
  color: #ffc107; /* Amarelo para em aberto */
}

/* Animação para ícones (girar) */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.resumo-pedidos-modal .modal-body {
  padding: 20px;
}

.resumo-pedidos-modal .modal-footer {
  border-top: 2px solid #ddd;
  background-color: #f0f4f8;
}
