.timeline {
    list-style-type: none;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #007bff;
    left: 50%;
    transform: translateX(-50%);
}

.timeline-item {
    width: 100%;
    max-width: 800px;
    margin: 20px 0;
}

.timeline-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.timeline-title {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.timeline-text {
    color: #555;
    margin-bottom: 10px;
}

.timeline-subtitle {
    font-style: italic;
    color: #777;
}
