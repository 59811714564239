/* Estilos para a notificação */
.notification {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #1877f2; /* Cor de fundo azul semelhante ao Facebook */
  padding: 10px;
  border-radius: 10px; /* Borda arredondada */
  margin-left: 10px; /* Espaçamento à esquerda */
  cursor: pointer; /* Mostrar cursor de mão ao passar o mouse */
  color: #fff; /* Cor do texto */
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  transition: background-color 0.2s; /* Efeito de transição de cor */
}

@media only screen and (max-width: 768px) {
  #title-navbar-info {
    display: none;
  }
}

.notification:hover {
  background-color: #1558b0; /* Cor de fundo da notificação ao passar o mouse */
}

/* Estilos para o ícone de sino */
.notification-icon {
  display: flex;
  align-items: center;
  position: relative; /* Posição relativa para elementos internos posicionados absolutamente */
}

.notification-icon svg {
  fill: #fff; /* Cor do ícone de sino */
  width: 24px;
  height: 24px;
  margin-right: 6px; /* Espaçamento à direita do ícone de sino */
}

/* Estilos para o contador de notificações */
.notification-count {
  background-color: #ff0000; /* Cor de fundo vermelha para o contador */
  color: #fff; /* Cor do texto do contador */
  border-radius: 50%; /* Borda circular para o contador */
  padding: 4px 8px; /* Espaçamento interno do contador */
  font-size: 12px; /* Tamanho do texto do contador */
  position: absolute; /* Posição absoluta para sobrepor o ícone */
  top: -8px; /* Posicione o contador acima do ícone */
  right: -8px; /* Posicione o contador à direita do ícone */
  animation: pulse 1s infinite; /* Animação de pulso para o contador */
}

/* Animação de pulso para o contador */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Estilo geral para a navbar */
.navbar {
  padding: 10px 20px;
}

/* Estilo para a imagem do logo */
.my-image {
  object-fit: contain;
}

/* Estilo para os links do menu */
.navbar-nav .nav-link {
  font-size: 16px;
}

/* Estilo para os links do menu quando expandidos */
.dropdown-menu .dropdown-item {
  font-size: 14px;
}

/* Estilo para o botão Pesquisar */
.btn-outline-light {
  font-size: 14px;
}

/* Sidebar.css */
.custom-navbar {
  background-color: #006ccd;
}

.brand-logo {
  width: 50px;
  height: 50px;
}

.menu-icon {
  margin-right: 5px;
}

.mercadolibre-icon {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  border-radius: 50%;
}
