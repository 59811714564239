.green-text {
  color: green;
}

.blue-text {
  color: blue;
}
.green-bg {
  background-color: green;
}

.red-text {
  color: red;
}

.delete-button {
  background-color: #dc3545; /* Cor de fundo vermelha */
  color: #fff; /* Cor do texto branca */
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #c82333; /* Cor de fundo mais escura quando hover */
}

ul.item-list {
  list-style: none;
  padding: 0;
}

ul.item-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/* Estilo para o botão "Retirar" */
ul.item-list li button {
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
}

/* Estilo para o modal */
.modal-dialog {
  max-width: 500px;
}

/* Estilo para a tabela de modal */
table.modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table.modal-table th,
table.modal-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table.modal-table th {
  background-color: #f7f7f7;
}

/* Estilo para os botões no rodapé do modal */
.modal-footer button {
  padding: 10px 20px;
}

/* Estilo para o botão "Retirar" no rodapé do modal */
.modal-footer button.btn-primary {
  background-color: #007bff;
  border: none;
}

/* retiradas.css */

.wms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.items-table {
  width: 100%;
  margin-top: 20px;
}

.items-table th {
  background-color: #f8f9fa;
  text-align: center;
}

.items-table td {
  text-align: center;
}

.modal-dialog {
  max-width: 400px;
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.modal-footer button {
  padding: 10px 20px;
}

.modal-footer button.btn-primary {
  background-color: #007bff;
  border: none;
}

/* retiradas.css */
/* retiradas.css */
.search-bar {
  position: relative;
  display: inline-block;
}

.search-input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

.custom-button-group {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* Ajuste este valor conforme necessário */
}

.custom-table-header {
  background-color: #f8f9fa;
}

.custom-header {
  font-weight: bold;
}
