.modalEnviarLeroy {
  min-width: 100%;
  min-height: 100vh;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.formRequest {
  display: flex;
  gap: 10px;
}

.productContainer {
  padding: 10px;
  margin: 10px;
}
