/* ProductList.css */

.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 95%; /* Ajusta a altura do card para ocupar o espaço disponível */
    text-align: center; /* Centraliza o texto dentro do card */
}

.product-card:hover {
    transform: scale(1.02);
}

.card-img {
    width: 50px; /* Define a largura da imagem como 50px */
    height: 50px; /* Define a altura da imagem como 50px */
    object-fit: cover; /* Garante que a imagem cubra a área sem distorção */
    margin: 0 auto; /* Centraliza a imagem horizontalmente */
}

.card-body {
    padding: 1rem; /* Ajusta o padding do corpo do card */
    display: flex;
    flex-direction: column;
    flex: 1; /* Faz com que o corpo do card ocupe o espaço restante */
}

.product-title {
    font-size: 0.85rem; /* Ajusta o tamanho do texto do título */
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.product-price {
    color: #333;
    font-size: 0.85rem; /* Ajusta o tamanho do texto do preço */
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.reputation-bar {
    height: 8px; /* Reduz a altura da barra de reputação */
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-top: 5px;
    overflow: hidden;
}

.reputation-fill {
    height: 100%;
    transition: width 0.3s ease;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination .page-item {
    cursor: pointer;
    font-size: 0.9rem; /* Ajusta o tamanho da fonte dos itens da paginação */
}

.pagination .page-item.active {
    font-weight: bold;
}
