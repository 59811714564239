#content-transfer {
  min-height: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.inputs article {
  display: flex;
  gap: 15px;
}

section#center {
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div#produtos-list {
  width: 800px;
  max-height: 300px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}

div#produtos article {
  width: 100%;
  cursor: pointer;
  user-select: none;
  background: #cdcdcd;
  border-radius: 10px;
  height: 60px;
  padding: 10px;
  margin: 10px 0;
  filter: opacity(75%);
  display: flex;
  align-items: center;
  justify-content: center;
}

div#produtos article:hover {
  filter: opacity(100%);
}

div#produtos article p {
  all: unset;
  font-size: 18px;
}

table tr td {
  font-size: 15px;
}
