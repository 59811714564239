.autocomplete {
  position: relative;
}

.autocomplete .btn {
}

.autocomplete .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.autocomplete-clear {
  color: #333; /* Cor do ícone */
  background-color: transparent; /* Fundo transparente */
  padding: 3px 5px; /* Espaçamento interno */
  height: 8px; /* Define a altura desejada */
}

.autocomplete-clear::before {
  opacity: 0; /* Torna apenas o fundo do botão transparente */
}

.table-products-search thead tr th {
  font-size: 12px;
}

.table-products-search tbody tr td {
  font-size: 12px;
}
