.message-icon-container {
  position: relative;
  display: inline-block;
}

.message-icon {
  font-size: 24px; /* Tamanho do ícone de mensagem */
  color: #333; /* Cor padrão do ícone */
  /* Adicione outros estilos de ícone, se necessário */
}

.message-count {
  position: absolute;
  top: -10px; /* Ajuste a posição vertical conforme necessário */
  right: -10px; /* Ajuste a posição horizontal conforme necessário */
  background-color: red; /* Cor de fundo da bola vermelha */
  color: white; /* Cor do texto da quantidade de notificações */
  border-radius: 50%; /* Forma circular para a bola vermelha */
  width: 20px; /* Largura da bola vermelha */
  height: 20px; /* Altura da bola vermelha */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px; /* Tamanho da fonte da quantidade de notificações */
}
