.container {
  min-width: 100vw;
  height: 100%;
}

.leroy-import-content {
  height: 100%;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
