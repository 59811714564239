.mini-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  width: 200px; /* Ajuste a largura conforme necessário */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stat-card:hover {
  transition: all 0.7s;
  transform: translateY(-10px);
}

.stat-card:hover svg {
  transition: all 0.7s;
  filter: brightness(1.08);
  transform: rotate(360deg);
}

.stat-card:not(:hover) svg {
  transition: filter 0.7s;
  filter: brightness(1);
}

.stat-card:not(:hover) {
  transition: all 0.7s;
  transform: translateY(0px);
}

/* MiniCard.css */
.mini-card-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 250px; /* Ajuste a largura conforme necessário */
  margin: 0 10px; /* Espaçamento entre os MiniCards */
  display: inline-block; /* Exibe os MiniCards horizontalmente */
}

/* Outros estilos do MiniCard conforme necessário */

.mini-card-header {
  background-color: #007bff;
  color: white;
  text-align: center;
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.mini-card-content {
  padding: 10px;
}

.mini-card p {
  margin: 0;
  padding: 3px 0;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.dashboard-container {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 5px;
}

/* Estilos dos itens do dashboard */
.dashboard-tile {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out;
}

.dashboard-tile:hover {
  transform: translateY(-5px);
}

.dashboard-icon {
  font-size: 36px;
  color: #0078d4; /* Cor azul do Windows 8 */
  margin-bottom: 10px;
}

.dashboard-tile-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.dashboard-value {
  font-size: 24px;
  color: #333;
}

/* Estilos específicos para cada tipo de item */
.tile-primary {
  background-color: #5cb85c; /* Verde do Windows 8 */
  color: #fff;
}

.tile-secondary {
  background-color: #5bc0de; /* Azul claro do Windows 8 */
  color: #fff;
}

.tile-tertiary {
  background-color: #f0ad4e; /* Laranja do Windows 8 */
  color: #fff;
}

.tile-quaternary {
  background-color: #d9534f; /* Vermelho do Windows 8 */
  color: #fff;
}

.tile-quinary {
  background-color: #428bca; /* Azul escuro do Windows 8 */
  color: #fff;
}

.tile-sextary {
  background-color: #337ab7; /* Azul mais escuro do Windows 8 */
  color: #fff;
}

.custom-list-item {
  border: 1px solid #e3e3e3;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.price {
  font-weight: bold;
  color: #00a650;
}

.dashboard-card:hover {
  transform: translateY(-5px);
}

.dashboard-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.dollar-icon {
  color: #00b74a;
}

.cart-icon {
  color: #ffc107;
}

.box-icon {
  color: #3e8aff;
}

.money-icon {
  color: #ff5722;
}

.cancel-icon {
  color: #e91e63;
}

/* FulfillmentDashboard.css */

.fulfillment-dashboard {
  margin-top: 30px;
}

.dashboard-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.dashboard-card {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.dashboard-card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.card-text strong {
  display: block;
  margin-top: 5px;
}

.date-selector-container {
  text-align: left; /* Alinhe à esquerda */
  align-items: flex-start; /* Alinhe verticalmente ao topo */
  margin-bottom: 20px; /* Opcional: Adicione margem inferior para espaçamento */
}

/* Estilos adicionais conforme necessário */

.dashboard-card-a {
  height: 200px; /* Defina a altura fixa desejada */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 20px;
}

.dashboard-card-title {
  font-size: 18px;
  margin-top: 10px;
}

.dashboard-value {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

/* Estilos para os Cards do Dashboard */

.dashboard-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.dashboard-card-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.dashboard-value {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

/* Estilos para cada tipo de card */
.dollar-card {
  background-color: #ffc107; /* Cor amarela */
}

.cart-card {
  background-color: #28a745; /* Cor verde */
}

.box-card {
  background-color: #007bff; /* Cor azul */
}

.money-card {
  background-color: #ff6b6b; /* Cor vermelha */
}

.cancel-card {
  background-color: #6c757d; /* Cor cinza */
}

.dashboard-chart-full {
  width: 100%;
  height: 100%;
}

.sales-ranking-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 20px;
}

.sales-progress-bar {
  width: 100%;
  height: 10px;
  background-color: #eee;
  margin-top: 5px;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.progress {
  height: 100%;
  background-color: #00aaff;
  transition: width 0.5s ease-in-out; /* Adicionando uma transição suave */
}

/* Estilização do componente order-details */
.order-details {
  background-color: #f7f7f7;
  padding: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 15px;
}

/* Estilo do título "Pedido #" */
.order-details h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Estilo do texto "Canal de Venda" */
.order-details p span.font-weight-bold {
  font-weight: bold;
  margin-right: 5px;
}

/* Estilo da imagem do logo */
.order-details img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Estilo dos valores de preço */
.order-details .price {
  font-weight: bold;
  color: #007bff; /* Cor do preço, pode ser personalizada */
}

.lottie-container {
  max-width: 200px; /* Defina o tamanho máximo desejado para a imagem Lottie */
  max-height: 200px; /* Defina o tamanho máximo desejado para a imagem Lottie */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Defina um valor alto para z-index para garantir que fique acima de outros elementos */
}

.popup-content {
  background-color: white;
  width: 80%; /* Defina a largura desejada, por exemplo, 80% */
  height: 60%; /* Defina a altura desejada, por exemplo, 60% */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  /* Centralize vertical e horizontalmente */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #0056b3;
}

/* Adicione estilos para outros tipos de cards aqui */
