/* Estilos para o modal da timeline */
.timeline-modal {
  .modal-content {
    border-radius: 3;
  }

  .modal-header {
    background-color: #007bff; /* Cor do botão primary */
    color: white;
  }
}

/* Estilos para a timeline */
.timeline {
  position: relative;
  padding: 20px;
  margin-top: 20px;
}

.timeline-item {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.timeline-badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: #17a2b8;
  border-radius: 50%;
}

.timeline-content {
  margin-left: 40px;
  border-left: 2px solid #17a2b8;
  padding-left: 20px;
}

.timeline-title {
  margin: 0;
  font-size: 18px;
  color: #17a2b8;
}

.timeline-text {
  font-size: 14px;
}

.timeline-subtitle {
  font-size: 12px;
  color: #868e96;
}

.sidebar {
  margin-top: 120px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 207px;
  background-color: white;
  border-left: 1px solid #dee2e6;
  padding: 15px;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.text-icon {
  color: white;
  size: 20px;
}

.btn-lateral {
  margin-top: 80px;
  position: fixed;
  left: 5;
  top: 0;
  right: 0;
  bottom: 1;
  width: 207px;
  padding: 5px;
  background-color: #198754; /* cor verde personalizada */
  border-color: #198754; /* cor da borda personalizada */
  color: #ffffff; /* cor do texto personalizada */
  border-left: 1px solid #dee2e6;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.meu-botao {
  width: 200px;
  height: 50px;
}

body {
  background-color: #f8f9fa;
}

.btnAddItem {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.btnAddItem:hover {
  background-color: #0062cc;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}
