div#items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div#items img {
  width: 50px;
  height: 50px;
}

input[type='checkbox']#check-balance:not(:checked) {
  background-color: #979797; /* Cor do checkbox desmarcado */
}

div#articles {
  margin: 5px 0;

  position: relative;
}

div#items article {
  width: 100%;
  min-width: 600px;
  max-width: 600px;
  padding: 15px;
  border-radius: 15px;
  background: #cacfd2;
  margin: 5px;
  position: relative;

  display: grid;
  grid-template-columns: 10% 10% 80%;
}

div#items article.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.7;
}

div#items article p {
  all: unset;
}

div#items article div {
  display: flex;
  flex-direction: column;
}

#faixa {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: -30px;
  background-color: #2ecc71;
  color: #fff;
  padding: 10px 20px;
  transform: rotate(45deg);
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
}
