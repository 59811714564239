article.product-card {
  width: 100%;
  height: auto;
  min-height: 80px;
  background: #e7e7e7;
  padding: 0 0 0 0;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

div#image {
  min-width: 100px;
  height: 100%;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

article.product-card strong {
  font-size: 12px;
}

article.product-card img {
  width: 20px;
  height: 20px;
}
