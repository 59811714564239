/* Adapte os estilos conforme necessário */
.date-range-picker {
  display: flex;
  flex-direction: column;
  /* Alterado para um layout de coluna */
  margin: 20px;
}

.date-column {
  margin-bottom: 20px;
  /* Adiciona margem entre as colunas */
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 1;
}

.custom-modalzpl {
  max-width: 90%;
  min-width: 90%;
  /* Define a largura máxima do modal */
  width: auto;
  /* Permite que o modal tenha uma largura automática */
}

.table-container {
  position: relative;
  overflow: visible;
}

/* Ajuste a propriedade position do dropdown para absolute */
.dropdown-menu {
  position: absolute !important;
}

.table-small {
  font-size: 0.85rem; /* Diminui o tamanho da fonte */
  width: 90%; /* Reduz a largura da tabela */
}

.table-small th,
.table-small td {
  padding: 0.5rem; /* Reduz o espaçamento interno das células */
}
