/* styles.css */
.table {
    font-size: 12px; /* Diminua o tamanho da fonte */
  }
  
  .table th,
  .table td {
    padding: 8px; /* Ajuste o preenchimento se necessário */
  }

  .escurecimento-completo-modal .modal-content {
    border: none;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3); /* Destaque para o modal */
  }
  
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.8) !important; /* Fundo bem escuro */
  }
  
  

  
  