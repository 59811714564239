.modal-children-heranca-overlay {
  min-width: 100vw;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;

  background: rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-children-heranca-content {
  width: 75%;
  height: 500px;
  border: none;
}

.modal-children-heranca-content-small {
  width: 45%;
  height: 500px;
  border: none;
}

.modal-children-heranca-content-large {
  width: 100%;
  height: 100%;
  border: none;
}
