/* FinanceScreen.css */
/* finance-screen.css */

.custom-table {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%; /* Ajuste conforme necessário */
}

.icon-success {
  color: green;
}

.icon-warning {
  color: orange;
}

.icon-danger {
  color: red;
}

.icon-primary {
  color: blue;
}

.container-fluid {
  padding: 20px;
}

.text-center {
  font-size: 24px;
  margin-bottom: 20px;
}

.custom-table {
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-table thead th {
  background-color: #f8f9fa;
  border-top: none;
  border-bottom: 2px solid #dee2e6;
  font-weight: bold;
}

.custom-table tbody td {
  border-top: none;
}

.table-success {
  background-color: #d4edda;
}

.table-danger {
  background-color: #f8d7da;
}

/* finance-screen.css */

.finance-card {
  flex: 1;
  text-align: center;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.finance-card .card-title {
  font-size: 1.2rem;
}

.finance-card .card-text {
  font-size: 1.5rem;
  font-weight: bold;
}
/* finance-screen.css */

.payment-method-card {
  text-align: center;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.payment-method-card .card-title {
  font-size: 1.2rem;
}

.payment-method-card .card-text {
  font-size: 1.5rem;
  font-weight: bold;
}
