.form-pedido-editar select {
  border: 0px;
  background-color: #fff;

  cursor: pointer;
  width: 100%;
  min-width: 200px;
}

.botao-estilizado {
  /* Adicione os estilos que você deseja para o botão */
  padding: 10px 20px;
  background-color: #007bff; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.botao-estilizado:hover {
  /* Estilo do botão ao passar o mouse */
  background-color: #0056b3; /* Cor de fundo alterada ao passar o mouse */
}

.no-item {
  width: 100%;
  height: 70px;
  border: 1px dashed #aeaeae;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: #999999;
}

.descricao-input {
  width: 300px; /* Ajuste o valor conforme necessário */
}

/* styles.css */

body {
  font-family: 'Open Sans', sans-serif;
}

/* Outros estilos globais */

