/* PackList.css */

.pack-list-container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

h1 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
}

/* PackList.css */

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

td {
  padding: 12px;
  text-align: left;
  /* Alinha o texto ao centro */
  border-bottom: 1px solid #e0e0e0;
}

th {
  background-color: #007bff;
  color: #fff;
  text-align: left;
}

tr:last-child td {
  border-bottom: none;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
  margin: 0;
}

.footer-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Adicione esses estilos ao seu arquivo PackList.css */

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-container input[type='text'],
.search-container input[type='date'],
.search-container button {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-container input[type='text'],
.search-container input[type='date'] {
  flex: 1;
  font-size: 16px;
}

.search-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.search-container button:hover {
  background-color: #0056b3;
}

/* Estilos adicionais conforme necessário */
