.table thead th {
  border-bottom: 1px solid #dee2e6;
  color: white;
  text-align: justify;
  white-space: nowrap;
  font-weight: normal;
}

.modal.modal-right .modal-dialog {
  transform: translateX(100%);
  margin-right: 0;
}

.modal.modal-right .modal-dialog.modal-dialog-scrollable {
  max-height: 100%;
  margin-right: 0;
}

.modal.modal-right .modal-dialog::after {
  display: none;
}

.modal.modal-right.show .modal-dialog {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.custom-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1050; /* Defina um valor de z-index adequado */
}

.sidebar-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 1050; /* Defina um valor de z-index adequado */
}

.sidebar-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Altere a cor de fundo conforme necessário */
}

.sidebar-content {
  position: relative;
  flex: 0 0 300px; /* Defina a largura desejada para a sidebar */
  background-color: #f9f9f9; /* Altere a cor de fundo conforme necessário */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar-content.open {
  transform: translateX(0);
}

.custom-table {
  font-size: 12px;
  padding: 4px;
  margin: 0;
  /* outras propriedades de estilo desejadas */
}

.entry-header {
  background-color: #f5f5f5;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.entry-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.entry-cnpj {
  font-size: 16px;
  color: #777;
}


