th {
  color: black !important;
  font-weight: bold !important;
  cursor: pointer;
}

.buttons-upon {
  display: flex;
}

.buttons-upon button svg {
  margin-right: 5px;
}

#container-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
