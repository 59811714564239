.modal-devolucoes-dialog {
  max-width: 100%;
  padding: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-devolucoes-content {
  max-width: 75%;
}

#content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

section#pedido {
  width: 100%;
  min-width: 600px;
  padding: 15px;
  border-radius: 15px;
  background: #cacfd2;
}

section#pedido div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

section#pedido article {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

section#pedido article p {
  all: unset;
  margin-left: 5px;
  font-size: 12px;
}

section#pedido button {
  width: 75%;
  margin: 0 auto;
}
